import React from 'react'
import {Row,Col,Button,Modal} from "react-bootstrap"
import { QRCodeImg } from '..'
import { FaCopy, FaPrint } from 'react-icons/fa'
import BarcodeTableData from './BarcodeTableData'
import { copyEvent } from '../../js/helpers'

interface QRModalProps {
  show : boolean,
  onHide : () => void,
  data : string,
}
const QRModal = (props: QRModalProps) => {
  // const copyEvent = () => {
  //   navigator.clipboard.writeText(props.data).then(()=>showAlert("QR Code Copied!",'success'))
  // }

  return (
    <Modal show={props.show} onHide={()=>props.onHide()} size="lg">
      <Modal.Header>
        <Modal.Title>QR Code : {props.data}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} sm={5} className="text-center">
            <Row>
              <Col xs={12}>
                <QRCodeImg size="l" value={props.data} noModal/>
              </Col>
              <Col xs={12} className="text-center">
                <Button className="mx-2"><FaPrint /></Button>
                <Button className="mx-2" onClick={()=>copyEvent(props.data,'QR Code Copied Successfully!')}><FaCopy/></Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={7} >
            <BarcodeTableData data={props.data} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default QRModal