import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { DataManager, Query, UrlAdaptor } from '@syncfusion/ej2-data';
import { AugTable } from '../../../../components/Table'
import ContentFrame from '../../../../ContentTemplate/ContentFrame'
import { base_url} from "../../../../components/apiHandler"

const ViewStorageBin = () => {

  const warehouseEdit = {
    params : {
      actionComplete : (a:any)=> {console.log(a); return false},
      allowFiltering:true,
      dataSource : new DataManager({        
        adaptor: new UrlAdaptor(),
        url : base_url + "urladapter/warehouse.php"
      }),
      fields: { text: "label", value: "value" },
      query: new Query()
    }
  }
  const storageEdit = {
    params : {
      actionComplete : (a:any)=> {console.log(a); return false},
      allowFiltering:true,
      dataSource : new DataManager({        
        adaptor: new UrlAdaptor(),
        url : base_url + "urladapter/storagetypes.php"
      }),
      fields: { text: "label", value: "value" },
      query: new Query()
    }
  }
  return (
    <ContentFrame title="Storage Bin">
      <Row>
        <Col xs={12}>These are the types of storage available for Items (Raw / Spare / Completed)</Col>
        <Col xs={12}>
          <AugTable 
            allowEditing
            dataManagerUrl={{url:'tables/storagebins.php'}}
            columns={[
              {headerText:'Id', isIdentity:true, field:'sb_id'},
              {headerText:'Warehouse', field:'w_name', editType:'dropdownEdit',edit:warehouseEdit},
              {headerText:'Type', field:'st_name', editType:'dropdownEdit',edit:storageEdit},
              {headerText:'Name', field:'sb_name'},
              {headerText:'Abbreviation', field:'sb_type_idx'},
              {headerText:'Prefix', isIdentity:true, field:'prefix'},
              {headerText:'Date Created', field:'sb_dateCreated', editType:'datetimepickeredit'}
            ]}
          /> 
        </Col>
      </Row>
    </ContentFrame>
  )
}

export default ViewStorageBin