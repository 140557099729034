import React from "react";
import {useForm, FormProvider} from "react-hook-form"

import {Button} from "react-bootstrap"

import "./Form.css" ;
import { FormInterface, FormSubmitButtonInterface } from "./interfaces";

export const Form = (FormProps: FormInterface) => {
  const formID = `form-${new Date().getTime()}`;
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: FormProps.defaultValues,
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined
  });

  const {  
      reset,
      handleSubmit
  } = methods;

  if (FormProps.expose_methods) {FormProps.expose_methods(methods)}
    
  return <>
    <FormProvider {...methods}>
      <form 
        onSubmit={
          handleSubmit(
            (data:any) => FormProps.onSubmit(data).then(()=>reset()),
            () => {if(FormProps.resetOnError) reset()})
        }
        id={formID}
      >
        {FormProps.children}
      </form>
    </FormProvider>
  </>
}

export const SubmitButton = (props: FormSubmitButtonInterface) => {
  return (<Button 
    className={`${props.className} px-4`} 
    variant={props.variant ? props.variant : "primary"} 
    type="submit"
  >
    {props.caption}
  </Button>)
}

export * from "./Inputs/SingleLineInputs";
export * from "./Inputs/Select/";
export * from "./Inputs/Lists/"
export * from "./Inputs/Photo"
export * from "./Inputs/QR"

