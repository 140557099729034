import React, {useState} from "react"
import { Button } from "react-bootstrap"
import { FaCopy, FaPrint, FaSearchPlus } from "react-icons/fa"
import QRCode from "react-qr-code"
import { QRCodeImgProps } from ".."
import { copyEvent } from "../../js/helpers"
import QRModal from "./QRModal"

export const QRCodeCont = (props: QRCodeImgProps) => {
  const [show, setShow] = useState<boolean>(false)

  return <div style={{position: 'relative'}}>
    {
      props.noModal !== true && <div>
        <QRModal show={show} onHide={()=>setShow(false)} data={props.value} />
        <div className="qr_cont_overlay" onClick={()=>setShow(true)}>
          <FaSearchPlus />
        </div>
      </div>
    }
    <QRCode           
      size={props.size === 's' ? 64 : 256} 
      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
      value={props.value}
      viewBox={`0 0 ${props.size === 's' ? '64' : '256'} ${props.size === 's' ? '64' : '256'}`}
    />
    {
      props.inline === true && <>
        <Button className="mx-2"><FaPrint /></Button>
        <Button className="mx-2" onClick={()=>copyEvent(props.value,'QR Code Copied Successfully!')}><FaCopy/></Button>
      </>
    }
  </div>
}