import React, { forwardRef } from 'react'
import { SidebarComponent} from '@syncfusion/ej2-react-navigations';
import { MenuComponent, MenuItemModel } from '@syncfusion/ej2-react-navigations';
import {useNavigate} from 'react-router-dom'
import "./sidebar.scss"

const menuItems: MenuItemModel[] = [  
  {
    text : "Incoming",
    iconCss : "fa fa-file-invoice-dollar text-warning",
    id : "/incoming",
    items : [
      {
        text : "Receiving",
        iconCss : "",
        id : "/incoming/receiving",
        items : [
          {
            text : "Add Goods Received",
            iconCss : "fa-solid fa-plus text-success",
            id : "/incoming/receiving/add"
          },
          {
            text : "View Receipts",
            iconCss : "fa-solid fa-eye text-info",
            id : "/incoming/receiving/view"
          },
        ]
      },
      {
        text : "QC",
        iconCss : "",
        id : "/incoming/qc",
        items : [
          {
            text : "Add Inspection Report",
            iconCss : "fa-solid fa-plus text-success",
            id : "/incoming/qc/add"
          },
          {
            text : "View Reports",
            iconCss : "fa-solid fa-eye text-info",
            id : "/incoming/qc/view"
          },
        ]
      }
    ]
  },
  {
      text: 'Warehouse',
      iconCss: 'fa fa-warehouse text-danger',
      items : [
        {
          text : "Add Inventory",
          iconCss : "fa fa-plus text-success",
          id : "/warehouse/add"
        },
        {
          text : "View Inventory",
          iconCss : "fa fa-eye text-info",
          id : "/warehouse/view"
        },
        {separator : true},
        {
          text : "Warehouse Locations",
          iconCss : "fa fa-boxes text-info",
          id : "/warehouse/wh/view"
        },
        {
          text : "Storage Types",
          iconCss : "fa-solid fa-cabinet-filing text-info",
          id : "/warehouse/st/view"
        },
        {
          text : "Storage Bins",
          iconCss : "fa fa-trash text-info",
          id : "/warehouse/sb/view"
        },
      ]
  },
  {
      text: 'Project',
      iconCss: 'fa fa-hammer text-danger',
      id : '/projects',
      items : [
        {
          text : "Add Material To Project",
          iconCss : "fa fa-warehouse text-danger",
          id : "/projects/material/add"
        },
        {
          text : "Return Material From Project",
          iconCss : "fa fa-warehouse text-primary",
          id : "/projects/material/return"
        }
      ]
  },
  {separator:true},  
  {
    text: 'Supplier',
    iconCss: 'fa fa-truck-field text-dark',
    id : '/supplier'
  },
  {
      text: 'Materials',
      iconCss: 'fa fa-wrench',
      id : '/materials',
      items : [
        {
          text : "Add New Material",
          iconCss : "fa fa-plus",
          id : "/materials/add"
        },
        {
          text : "View Catalogue",
          iconCss : "fa fa-book",
          id : "/materials/view"
        }
      ]
  },
  
];

const SideBar = forwardRef((props:any, ref:any) => {

  const navigate = useNavigate();
  
  const select = (args:any) => {
    navigate(args.item.id)
  }

  return (
        <SidebarComponent 
          id="menuSidebar" 
          className="sidebar-menu" 
          ref={ref} 
          enableDock={true} 
          dockSize={'50px'} 
          width={'220px'} 
          target={'.main-menu-content'} 
          isOpen={true} 
          type="Auto" 
        >
          <div className="main-menu h-100">
            <div className="h-100">
                <MenuComponent select={select} id="dockMenu" items={menuItems} orientation='Vertical' cssClass='dock-menu'></MenuComponent>
            </div>
          </div>
        </SidebarComponent>
  )
})

export default SideBar