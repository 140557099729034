import React from "react";
import { registerLicense } from "@syncfusion/ej2-base";
import { enableRipple } from '@syncfusion/ej2-base';
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import AddMaterial from "./content/Material/AddMaterial";
import ViewMaterials from "./content/Material/ViewMaterials";

import ViewWarehouses from "./content/Warehouse/View"
import ViewStorageTypes from "./content/Warehouse/StorageTypes/View"
import ViewStorageBins from "./content/Warehouse/StorageBin/View"

import "@fortawesome/fontawesome-free/css/fontawesome.min.css"
import "@fortawesome/fontawesome-free/css/brands.min.css"
import "@fortawesome/fontawesome-free/css/solid.min.css"
import { ThreeCircles } from "react-loader-spinner";
import ViewInventory from "./content/Warehouse/Inventory/View";
import Supplier from "./content/Supplier";
import ViewGoodsReceivedReceipts from "./content/Incoming/Receiving/View";
import ReceivingIndexPage from "./content/Incoming/Receiving";
import IncomingIndex from "./content/Incoming";

const AddGoodsReceived = React.lazy(() => import("./content/Incoming/Receiving/Add"))
const AddQCReport = React.lazy(() => import("./content/Incoming/QC/Add"))
const ViewQC = React.lazy(() => import("./content/Incoming/QC/View"))
const MaterialIndexPage = React.lazy(() => import("./content/Material"))
const QCIndexPage = React.lazy(() => import("./content/Incoming/QC"))
const AddMaterialToProject = React.lazy(() => import("./content/Project/AddMaterial"))
const AddInventory = React.lazy(()=>import("./content/Warehouse/Inventory/Add"))
const ReturnMaterial = React.lazy(() => import("./content/Project/ReturnMaterial"))

registerLicense("ORg4AjUWIQA/Gnt2VVhiQlFacltJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1hW39ccX1VT2FYWUU=");
enableRipple(true);

const SuspenseWrapper = ({ children}:{children:React.ReactElement}) => {
  return <React.Suspense fallback={<ThreeCircles
    height="200"
    width="200"
    color="#2dbe2a"
    wrapperStyle={{top:'0',left:"0",background:'#f2f2f233',position:'absolute', width:'100vw',height:'100vh',display:'flex',alignItems: 'center',justifyContent: 'center'}}
    wrapperClass=""
    visible={true}
    ariaLabel="three-circles-rotating"
    outerCircleColor=""
    innerCircleColor="#fe4444"
    middleCircleColor="#4b4bdc"
  />}>
    {children}
  </React.Suspense>
}


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          {/* ADDITIONAL ROUTES GO HERE */}
          <Route path="supplier" element={<Supplier />}/>
          <Route path="incoming" element={<IncomingIndex />}>
            <Route path="receiving" element={<ReceivingIndexPage />}>
              <Route path="add" element={<SuspenseWrapper><AddGoodsReceived /></SuspenseWrapper>} />
              <Route path="view" element={<ViewGoodsReceivedReceipts />} />
            </Route>
            <Route path="qc" element={<SuspenseWrapper><QCIndexPage /></SuspenseWrapper>}>
              <Route path="add" element={<SuspenseWrapper><AddQCReport /></SuspenseWrapper>}  />
              <Route path="view" element={<SuspenseWrapper><ViewQC /></SuspenseWrapper>}/>
            </Route>
          </Route>
          <Route path="materials" element={<SuspenseWrapper><MaterialIndexPage /></SuspenseWrapper>}>
            <Route path="add" element={<SuspenseWrapper><AddMaterial /></SuspenseWrapper>} />
            <Route path="view" element={<ViewMaterials />} />
          </Route>
          <Route path="warehouse">
            <Route path="wh/view" element={<ViewWarehouses />} />
            <Route path="st/view" element={<ViewStorageTypes />} />
            <Route path="sb/view" element={<ViewStorageBins />} />
            <Route path="add" element={<SuspenseWrapper><AddInventory/></SuspenseWrapper>} />
            <Route path="view" element={<ViewInventory />} />
          </Route>
          <Route path="projects" element={<SuspenseWrapper><MaterialIndexPage /></SuspenseWrapper>}>
            <Route path="material/add" element={<SuspenseWrapper><AddMaterialToProject /></SuspenseWrapper>} />
            <Route path="material/return" element={<SuspenseWrapper><ReturnMaterial /></SuspenseWrapper>} />
          </Route>

          {/* ADDITIONAL ROUTES GO HERE */}
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

