import {useState, useEffect} from "react"
import {useFormContext, Controller} from "react-hook-form"
import { FormObjectInterface } from "../../interfaces";

import {Form} from "react-bootstrap"

import Frame from "../../Components/Frame"

export const Line = (props: FormObjectInterface) => {
  const methods = useFormContext();  
  const [lineValue, setLineValue] = useState<any>('');

  useEffect(()=>{
    if (props.readOnlyValue) { methods.setValue(props.name,props.readOnlyValue) }
    // eslint-disable-next-line
  },[props?.readOnlyValue])
  
  const toWatch = props.calculatedField ? methods.watch(props.calculatedField.find) : null

  useEffect(()=> {
    if (toWatch === null) {}
    else {
      if (props.calculatedField) {
        methods.setValue(props.name, props.calculatedField.calculation(toWatch))
      }
    }
    // eslint-disable-next-line
  }, [toWatch])

  const process_change = (val : any, onChange: (...event: any[]) => void) => {
    let _val = val;
    if (props.extValidatorSetter) {_val = props.extValidatorSetter(val,methods.getValues())}

    console.log("[process_change] - val",_val)
    if (props.setValue) { 
      if (typeof props.setValue.value === 'function') {
        methods.setValue(props.setValue.inputName, props.setValue.value(_val, methods.getValues(props.setValue.inputName), methods.getValues()))
      } else {
        methods.setValue(props.setValue.inputName, props.setValue.value)
      }
    }
    onChange(_val)
    setLineValue(_val)
  }

  return (
    <Frame {...props} value={lineValue}>
      <Controller
        control={methods.control}
        name={props.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <Form.Control
            className={`form-control ${props.className ?? ''}`}
            disabled={props.disabled || props.readOnlyValue ? true : false}
            // {...props}     
            min={props.min}
            max={props.max}
            minLength={props.minLength}
            maxLength={props.maxLength}
            readOnly={props.readOnly}
            type={props.type}       
            placeholder={props.placeholder ? props.placeholder : props.caption ? props.caption + "..." : ''}
            onBlur={onBlur} // notify when input is touched
            onChange={(c)=>{process_change(c.target.value, onChange)}} // send value to hook form
            value={value}
          />
        )}
      />
    </Frame>
  )
}