import React from "react"
import {useFormContext, Controller} from "react-hook-form"
import { FormCurrencyInterface } from "../../interfaces";
import CurrencyInput from 'react-currency-input-field'
import Frame from "../../Components/Frame"

export const Currency  = (props: FormCurrencyInterface) => {
  const methods = useFormContext();
  
  const process_change = (val : any) => {
    console.log("[process_change] - val",val)
    if (props.setValue) { 
      if (typeof props.setValue.value === 'function') {
        methods.setValue(props.setValue.inputName, props.setValue.value(val, methods.getValues(props.setValue.inputName), methods.getValues()))
      } else {
        methods.setValue(props.setValue.inputName, props.setValue.value)
      }
    }
  }

  return (    
    <Frame {...props}>
      <Controller 
        control={methods.control}
        name={props.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <CurrencyInput
            className={`form-control ${props.className ?? ''}`}
            id={`currency-${new Date().getTime()}`}
            name={name}
            placeholder={props.placeholder ?? "Please enter a value"}
            defaultValue={0}
            value={value}
            decimalsLimit={3}
            fixedDecimalLength={3}
            prefix={props.prefix ?? "RM "}
            onValueChange={(value, name) => {onChange(value); process_change(value)}}
          />
        )}
      />
    </Frame>
  )
}
