import React from 'react'
import {Col} from "react-bootstrap"
import {  Form, Line,SubmitButton, TableList } from '../../../../components/Form'
import FormSection from '../../../../ContentTemplate/FormFrame/FormSection'
import { FormSelectOptionsInterface } from '../../../../components/Form/interfaces'
import UppyUploader from '../../../../components/Form/Inputs/Photo/uppy'
import { loadOptions } from '../../../../components/js/SelectOptions'
import { IStandardForm } from '../../../interfaces'

export interface IAddMaterialReturnForm {
  name : string
  images : string[],
  description : string,
  threshold : number,
  stock : {quantity: number,threshold : number, bin : FormSelectOptionsInterface}[]
}
export interface IAddMaterialForm extends IStandardForm<IAddMaterialReturnForm> {
}



export const AddMaterialForm = (props : IAddMaterialForm) => {
  
  const _defaultValues = {
    stock : [
      {quantity: 0 ,bin : ''}
    ],
    ...props.defaultValues ?? {}
  }

  const presubmit = (a:IAddMaterialReturnForm) => new Promise((resolve,reject) => {
    resolve(props.onSubmit(a))
  })

  return (
    <Form onSubmit={presubmit} defaultValues={_defaultValues}>
      <FormSection header="General Details">
        <Col xs={12} sm={2}><UppyUploader name="images" caption="Image(s)" /></Col>
        <Col xs={12} sm={6}><Line name="name" caption="Item Name" /></Col>
        <Col xs={12} sm={4}><Line type="number" name="threshold" caption="Threshold (For Reorder)" /></Col>
        <Col xs={12} sm={12}><Line name="description" caption="Description" /></Col>
      </FormSection>
      <FormSection header="Existing Stock (Adding to Bin Catalogue)">
        <TableList 
          header={
            <tr>
              <th></th>
              <th style={{width:'200px'}}>Quantity</th>
              <th style={{width:'200px'}}>Reorder Threshold</th>
              <th>Bin</th>
              <th></th>
            </tr>
          }
          emptyRow={{quantity:0,bin:''}}
          name="stock"
          items={[
            {type: 'number', name:'quantity'},
            {type: 'number', name:'threshold'},
            {type: 'select_async_qr', name:'bin' ,loadOptions : (a:string|string[]) => loadOptions('storage_bin',a)},

          ]}
        />
      </FormSection>
      <FormSection header="">
        <Col xs={12}><SubmitButton caption="Create New Material" /></Col>
      </FormSection>
    </Form>
  )
}
