import React, { useState, useEffect } from "react";
import parse from "html-react-parser";

import { FaArrowDown, FaArrowUp, FaTrash } from "react-icons/fa";

import { useFieldArray, useFormContext } from "react-hook-form";
import InputChooser from "../../Components/InputChooser";
import { IInputToTableList } from "../../interfaces";
import { Col, Modal, Row, ButtonGroup, Button, Table } from "react-bootstrap";
import { Form, SubmitButton } from "../../index";

export const InputToTableList = (props: IInputToTableList) => {
  const [editModal, setEditModal] = useState<any>(null);
  const [data, setData] = useState<any[]>([]);

  const methods = useFormContext();
  const {
    // fields,
    append,
    remove,
    update,
    move,
  } = useFieldArray({
    control: methods.control,
    name: props.name,
  });

  const addItem = () => {
    const items = methods.getValues(props.rootName);
    console.log("[AddItem] - Items :", JSON.parse(JSON.stringify(items)));
    console.log("[AddItem] - Data :", JSON.parse(JSON.stringify(data)));
    console.log(
      "[AddItem] - Together :",
      JSON.parse(JSON.stringify([...data, items]))
    );
    append(items);
    setData(methods.getValues(props.name));
  };

  useEffect(() => {
    if (editModal !== null) {
      const _items = methods.getValues(
        `${props.name}.${editModal._fieldIndex}`
      );
      console.log("EDIT MODAL", _items);
      console.log("EDIT MODAL itl-edit", methods.getValues("itl-edit"));
      methods.setValue("itl-edit", _items);
    }
    // eslint-disable-next-line
  }, [editModal]);

  // const editItem = (index: number, data : any) => {
  //   setEditModal({...data, _fieldIndex : index})
  // }

  const deleteItem = (index: number) => {
    remove(index);
    setData(data.filter((x, i) => i !== index));
  };

  const headerGenerator = () => {
    return (
      props.header ?? (
        <tr>
          <th></th>
          {props.items.map((item, index) => (
            <th key={`tl-hg-th-${index}`}>{item.caption}</th>
          ))}
          {props.fixed !== true && <th></th>}
        </tr>
      )
    );
  };

  // const bodyGenerator = () => {
  //   const groupTable = (items:any, group:number, index:number, mainIndex:number) => {
  //     let _items = [];
  //     console.log(`[groupTable] - group : ${group}, index : ${index}, mainIndex : ${mainIndex}`)
  //     console.log("[groupTable] - items ",items)
  //     for (let i = index; i < index + group; i++) {
  //       _items.push(
  //         <tr>
  //           <td>
  //             <InputChooser
  //               {...items[i]}
  //               type="plaintext"
  //               name={`${props.name}.${mainIndex}.${items[i].name}`}
  //               showTitle={false}
  //               />
  //           </td>
  //         </tr>
  //       )
  //     }

  //     return <table className="w-100">{_items}</table>
  //   }

  //   // group will group the next input as its own row, instead having them in one cell together
  //   let group = 0;
  //   return fields.map( (field:any, index:number) => (
  //     <tr key={`tb-bg-tr-${index}`} style={{verticalAlign:'middle'}}>
  //       <td>{index + 1}</td>
  //       {
  //         props.items.map( (item:any, itemIndex:number,items:any) => {
  //           let _itemTD = group > 0 ? null :
  //           <td key={`${field.id}-${itemIndex}`} style={{width: item?.width ?? 'auto'}}>
  //             {
  //               item?.group > 0 ?
  //               groupTable(items, item.group, itemIndex, index)
  //               : <InputChooser readOnly {...item} name={`${props.name}.${index}.${item.name}`} showTitle={false} />
  //             }
  //           </td>

  //           group =  item?.group ? item.group - 1 :
  //                   group > 0 ? group - 1 :
  //                   group
  //           return _itemTD
  //         })
  //       }
  //       <td>
  //         <ButtonGroup>
  //           <Button onClick={()=>editItem(index)} variant="success"><FaEdit/></Button>
  //           <Button onClick={()=>remove(index)} variant="danger"><FaTrash/></Button>
  //         </ButtonGroup>
  //       </td>
  //     </tr>
  //   ))
  // }

  const bodyGenerator = () => {
    const parseValue = (value: any, hasHtml?: boolean) => {
      if (value === undefined) {
        return "-";
      } else if (typeof value === "boolean") {
        return value ? "YES" : "NO";
      } else if (typeof value === "object") {
        if (value instanceof Date) {
          return value.toLocaleDateString();
        } else if (value?.label !== undefined) {
          return value.label;
        } else if (value?.name !== undefined) {
          console.log("WHATS THIS", value);
          return value?.name;
        } else {
          return value.value;
        }
      } else if (hasHtml) {
        return parse(value);
      } else {
        return value;
      }
    };
    const captions = props.items.map((item) => item.caption);
    const _keys = props.items.map((item) => item.name);

    const createMoveArrows = (i: number, max: number) => {
      return (
        <span className="ms-1">
          {i !== 0 && <FaArrowUp onClick={() => move(i, i - 1)} />}
          {i !== max && <FaArrowDown onClick={() => move(i, i + 1)} />}
        </span>
      );
    };

    return data.map((_data, _index) => (
      <tr key={`_bg-dt-${_index}-${props.name}`}>
        <td>
          {_index + 1}
          {props.allowMove && createMoveArrows(_index, data.length - 1)}
        </td>
        {captions.map((caption, __index) => (
          <td key={`_bg-dt-${_index}-${props.name}-${__index}`}>
            {parseValue(
              _data[_keys[__index]],
              props.items[__index]?.hasHtml ?? false
            )}
          </td>
        ))}
        <td>
          <ButtonGroup>
            {/* <Button onClick={()=>editItem(_index, _data)} variant="outline-success"><FaEdit/></Button> */}
            <Button onClick={() => deleteItem(_index)} variant="outline-danger">
              <FaTrash />
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    ));
  };

  const editItemConfirm = (data: any) =>
    new Promise<void>((resolve, reject) => {
      if (editModal !== null) {
        console.log("EDIT MODAL Updating Item : ", data);
        update(editModal._fieldIndex, data);
        setEditModal(null);
      }
    });

  return (
    <Row>
      <Modal show={editModal !== null} onHide={() => setEditModal(null)}>
        {editModal !== null && (
          <Modal.Header>
            <Modal.Title>Edit Item - {editModal._fieldIndex + 1}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <Row>
            <Form onSubmit={editItemConfirm}>
              {props.items.map((item, index) => (
                <Col xs={12} key={`mb-em-${props.name}-${index}`}>
                  <InputChooser {...item} name={`itl-edit.${item.name}`} />
                </Col>
              ))}
              <SubmitButton caption="Edit Item" />
            </Form>
          </Row>
        </Modal.Body>
      </Modal>
      <Col xs={12}>{props.children}</Col>
      <Col xs={12} className="text-end mb-2">
        <Button onClick={() => addItem()}>Add Item</Button>
      </Col>
      <Col xs={12}>
        <Table
          striped
          bordered
          responsive
          size={props.small ? "sm" : undefined}
        >
          <thead>{headerGenerator()}</thead>
          <tbody>{bodyGenerator()}</tbody>
        </Table>
      </Col>
    </Row>
  );
};
