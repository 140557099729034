import { POSTRequest } from "../../apiHandler"

export const handleItemQR = (data, options) => new Promise((resolve, reject) => {
  POSTRequest("qr.php","QR_ITEM",{qr_text : data, ...options})
    .then(response => resolve({...response, isQR : true}))
})

export const handleWildCardQR = (data, options) => new Promise((resolve, reject) => {
  POSTRequest("qr.php","QR_WILDCARD",{qr_text : data, ...options})
    .then(response => resolve(response))
})