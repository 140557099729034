import React from 'react'
import Frame from "./Frame"
import {Line} from "../Inputs/SingleLineInputs"
import {SelectCreatable, SelectNoAdd} from "../Inputs/Select"
import { Currency } from '../Inputs/SingleLineInputs/Currency'
import SelectWithQR from '../Inputs/Select/SelectWithQR'
import UppyUploader from '../Inputs/Photo/uppy'
import SelectWithQRAsync from '../Inputs/Select/SelectWithQRAsync'

const InputChooser = (props:any) => {
  let chosenElem = <></>;
  switch (props?.type) {
    case "text":
      chosenElem = <Line {...props}/>
      break;
    case "number":
      chosenElem = <Line {...props}/>
      break;
    case "select_no_add":
      chosenElem = <SelectNoAdd {...props}/>
      break;
    case "select_creatable":
      chosenElem = <SelectCreatable {...props}/>
      break; 
    case "select_creatable_qr":
      chosenElem = <SelectWithQR {...props}/>
      break; 
    case "select_async_qr":
      chosenElem = <SelectWithQRAsync {...props}/>
      break; 
    case "currency":
      chosenElem = <Currency {...props}/>
      break;
    case "photo":
      chosenElem = <UppyUploader {...props}/>
      break;
    default:
      chosenElem = <Line {...props}/>
      break;
  }
  return (
    <Frame {...props}>
      {chosenElem}
    </Frame>
  )
}

export default InputChooser