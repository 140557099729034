import React from 'react'

import {Row,Col} from "react-bootstrap"

interface FormSectionProps {
  header : string,
  children ?: React.ReactNode
}

const FormSection = (props : FormSectionProps) => {
  return (
    <Row className="m-2 border">
      <Col xs={12}><p className="mt-3 mb-2 h5 fw-bold">{props.header}</p></Col>
      {props.children}
    </Row>
  )
}

export default FormSection