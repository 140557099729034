import React,{useState} from 'react'

import {Button, ButtonGroup} from "react-bootstrap"
import { LotTableItemsFields } from '..'
import LotModal from './LotModal'

interface ItemsButtonGroupProps {
  data: LotTableItemsFields
}

const ItemsButtonGroup = (props: ItemsButtonGroupProps) => {
  const [show, setShow] = useState<boolean>(false)
  return (<>
    <LotModal data={props.data} show={show} onHide={()=>setShow(false)}/>
    <ButtonGroup vertical style={{fontSize:'1em'}}>    
      <Button style={{fontSize:'1em'}} variant="outline-dark" onClick={()=>setShow(true)}>View Details</Button> 
      <Button style={{fontSize:'1em'}} variant="outline-danger" onClick={()=>null}>Change Status</Button> 
    </ButtonGroup>
  </>)
}

export default ItemsButtonGroup