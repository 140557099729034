import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { AugTable } from '../../../components/Table'
import ContentFrame from '../../../ContentTemplate/ContentFrame'

const ViewWarehouse = () => {
  return (
    <ContentFrame title="Warehouse Listing">
      <Row>
        <Col xs={12}><p>These are the warehouses currently tracked with this system</p></Col>
        <Col xs={12}>
          <AugTable 
            allowEditing
            dataManagerUrl={{url:'tables/warehouse.php'}}
            columns={[
              {headerText:'Id', isIdentity:true, field:'w_id'},
              {headerText:'Name', field:'w_name'},
              {headerText:'Location', field:'w_location'},
              {headerText:'Abbreviation', field:'w_type_idx'},
              {headerText:'Date Created', field:'w_dateCreated', editType:'datetimepickeredit'}
            ]}
          />
        </Col>
      </Row>
    </ContentFrame>
  )
}

export default ViewWarehouse