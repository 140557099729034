import React from 'react'

import {Button, ButtonGroup,Table} from "react-bootstrap"

import {FaPlus,FaMinus} from "react-icons/fa"

import { useFieldArray, useFormContext } from 'react-hook-form';
import InputChooser from '../../Components/InputChooser';
import { TableListProps } from '../../interfaces'
import { QRWithButton } from '../QR/QRWithButton'
import { TooltipWrapper } from '../../../Tooltip'

export const TableList = (props: TableListProps) => {
  
  const methods = useFormContext();
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control : methods.control,
    name: props.name
  });

  const handleScan = (data:any) =>  {
    if (props.QRAppend) {
      props.QRAppend.action(data.text, methods.getValues()).then((response) => {
        // Check if theres any data first
        const _table_data = methods.getValues(props.name)
        const _emptyRowVals = Object.keys(props.emptyRow)
        if (
          _table_data.length === 1 && 
          _emptyRowVals.map(itm => props.emptyRow[itm] === _table_data[0][itm]).reduce((a:boolean,b:boolean) => a&&b,true)
        ) { methods.setValue(props.name, [response])}
        else {append(response)}
      });
    }
  }

  const headerGenerator = () => {
    return <>
    {
      props.QRAppend && (
        <tr>
          <th colSpan={2}>
            <TooltipWrapper tooltip={"Append New Item by QR"}>
              <QRWithButton handleScan={handleScan}/>
            </TooltipWrapper>            
            <div >SCAN QR</div>
          </th>
        </tr>
      )
    }
    { 
      props.header ?? (
      <tr>
        <th></th>
        {
          props.items.map((item,index) => (
            <th key={`tl-hg-th-${index}`}>{item.caption}</th>
          ))
        }
        {
          props.fixed !== true && <th></th>
        }
      </tr>
      )
    }

    
    </>
  }

  const bodyGenerator = () => {    
    const groupTable = (items:any, group:number, index:number, mainIndex:number) => { 
      let _items = [];
      console.log(`[groupTable] - group : ${group}, index : ${index}, mainIndex : ${mainIndex}`)
      console.log("[groupTable] - items ",items)
      for (let i = index; i < index + group; i++) {
        _items.push(
          <tr>
            <td>
              <InputChooser
                {...items[i]}
                name={`${props.name}.${mainIndex}.${items[i].name}`}
                showTitle={false}
                />
            </td>
          </tr>
        )
      }

      return <table className="w-100">{_items}</table>
    }

    // group will group the next input as its own row, instead having them in one cell together
    let group = 0;
    return fields.map( (field:any, index:number) => (
      <tr key={`tb-bg-tr-${index}`} style={{verticalAlign:'middle'}}>
        <td>{index + 1}</td>
        {
          props.items.map( (item:any, itemIndex:number,items:any) => {
            let _itemTD = group > 0 ? null :
            <td key={`${field.id}-${itemIndex}`} style={{width: item?.width ?? 'auto'}}>
              {
                item?.group > 0 ? 
                groupTable(items, item.group, itemIndex, index)
                : <InputChooser
                  {...item}
                  name={`${props.name}.${index}.${item.name}`}
                  showTitle={false}
                />
              }
            </td>

            group =  item?.group ? item.group - 1 : 
                    group > 0 ? group - 1 : 
                    group
            return _itemTD
          })
        }
        {
          props.fixed !== true && (
            <td className="text-end text-xs-center">
              <ButtonGroup>
                <Button onClick={()=>{append(props.emptyRow)}}>
                  <FaPlus/>
                </Button>
                <Button 
                  onClick={()=>{remove(index)}}
                  disabled={fields.length<=1}
                >
                  <FaMinus/>
                </Button>
              </ButtonGroup>
            </td>
          )
        }
      </tr>
    ))
  }

  const footerGenerator = () => {
    return props.footer ?? null
  }

  return (
    <Table striped bordered responsive size={props.small ? 'sm' : undefined}>
      <thead>
        {headerGenerator()}
      </thead>
      <tbody>
        {bodyGenerator()}
      </tbody>
      <tfoot>
        {footerGenerator()}
      </tfoot>
    </Table>
  )
}
