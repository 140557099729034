import {useState} from "react"
import { Button, Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FaQrcode } from "react-icons/fa";
import Frame from "../../Components/Frame";
import { FormQRAsyncInterface } from "../../interfaces";
import { QRContainer } from "../QR";
import { AsyncSelectCreatable, AsyncSelectInput } from "./AsyncSelect";


const SelectWithQRAsync = (props : FormQRAsyncInterface) => {
  const [scanning, setScanning] = useState<boolean>(false)
  const methods = useFormContext();
  
  const handleError = (error: any) => {}
  const handleScan = (data: any) => {
    console.log("QRData",data)  

    // Validation , if valid then select value
    if (props.qrValidation) {
      props.qrValidation(data.text).then((returnVal) => {
        if (returnVal.status) {
          console.log("[qrValidation] - Return Value : ",returnVal, methods.getValues(props.name))
          if (props.valueAsState) {props.valueAsState(returnVal.data)}
          methods.setValue(props.name, returnVal.data, {shouldValidate : true}) 
        }
      })
    }
  }

  return (
    <Frame {...props}>
      <Row>
        <QRContainer show={scanning} onHide={()=>setScanning(false)} delay={100} handleScan={handleScan} handleError={handleError} style={{width: 240}} />
        <Col xs={2} >
          <Button onClick={()=>{setScanning(!scanning)}} variant="outline-dark" ><FaQrcode style={{fontSize:'1.3em'}}/></Button>          
        </Col>
        <Col xs={10}>
          {
            props.onCreate ? 
              <AsyncSelectCreatable noFrame {...props} caption="" name={props.name} onCreate={props.onCreate ?? ((dat:string)=>null)} options={props.options} /> :
              <AsyncSelectInput noFrame {...props} caption="" name={props.name} options={props.options} />
          }
        </Col>
      </Row>
    </Frame>
  )
}

export default SelectWithQRAsync