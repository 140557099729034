import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { AugTable } from '../../../../components/Table'
import ContentFrame from '../../../../ContentTemplate/ContentFrame'

const ViewStorageTypes = () => {
  return (
    <ContentFrame title="Storage Types">
      <Row>
        <Col xs={12}>These are the types of storage available for Items (Raw / Spare / Completed)</Col>
        <Col xs={12}>
          <AugTable 
            allowEditing
            dataManagerUrl={{url:'tables/storagetypes.php'}}
            columns={[
              {headerText:'Id', isIdentity:true, field:'st_id'},
              {headerText:'Name', field:'st_name'},
              {headerText:'Abbreviation', field:'st_type_idx'},
              {headerText:'Date Created', field:'st_dateCreated', editType:'datetimepickeredit'}
            ]}
          />
        </Col>
      </Row>
    </ContentFrame>
  )
}

export default ViewStorageTypes