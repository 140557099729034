import React from 'react'
import {FormObjectFrameInterface} from "../interfaces"
import {Button, Form, InputGroup} from "react-bootstrap"

interface IFrameWrapper extends FormObjectFrameInterface {children : React.ReactElement}
const FrameWrapper = (props: IFrameWrapper) => {
  return props.buttonLeft !== undefined || props.buttonRight !== undefined ? 
    <InputGroup className={`${props.showTitle !== false ? 'mb-3' : ''} ${props.className ?? ''}`} >{props.children}</InputGroup> : 
    <Form.Group className={`${props.showTitle !== false ? 'mb-3' : ''} ${props.className ?? ''}`} controlId={`fi-${Math.floor(Math.random() * 500)}`}>{props.children}</Form.Group>
}

const Frame = (props:FormObjectFrameInterface) => {  
  const generateButtons = (dir: 'left'| 'right', items : {caption: React.ReactNode, onClick : any, variant ?: string}[]) => {
    return items.map((item, index) => {
      return <Button key={`fgb-${props.name}-${index}-${dir}`} onClick={(e) => item.onClick(e)} variant={item.variant} >{item.caption}</Button>
    })
  }
  return (
    <FrameWrapper {...props}>
      <>
        {props.buttonLeft !== undefined && generateButtons('left', props.buttonLeft)}
        {props.showTitle !== false && <Form.Label className="form-captions d-flex justify-content-between">
          <span className="text-start">{props.caption}</span>
          <span className="text-end">{props.maxLength? `${props.maxLength - (props?.value?.length ?? 0)} character(s) remaining` : ''}</span>
        </Form.Label>}
        {props.children}
        {props.buttonRight !== undefined && generateButtons('right', props.buttonRight)}
      </>
    </FrameWrapper>
  )
}

export default Frame