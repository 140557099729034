import React, {useEffect} from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const ReceivingIndexPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(()=> {
    if (location.pathname === "/incoming/receiving") {navigate("/incoming/receiving/add")}
  // eslint-disable-next-line
  },[location.pathname])
  
  return (
    <Outlet/>
  )
}

export default ReceivingIndexPage