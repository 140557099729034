import React from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import { FaSearch, FaTruck } from 'react-icons/fa';
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const IncomingIndex = () => {
  const location = useLocation()
  const navigate = useNavigate();

  return (
    <Row>
      {location.pathname === "/incoming" && <Col xs={12} className="text-center">
        <Button style={{height:'3em',fontSize:'2em', margin:'1em'}} onClick={()=> navigate("receiving")}><FaTruck /> Receiving</Button>
        <Button style={{height:'3em',fontSize:'2em', margin:'1em'}} onClick={()=> navigate("qc")}><FaSearch/> QC Check</Button>
      </Col>}
      <Col xs={12}><Outlet/></Col>
    </Row>
  )
}

export default IncomingIndex