import React, {useState, useMemo, useRef} from 'react'
import Uppy from '@uppy/core'
import DefaultStore from '@uppy/store-default'
import XHRUpload from '@uppy/xhr-upload'
import { DashboardModal, useUppy  } from '@uppy/react'
import {Row,  Button, Badge} from "react-bootstrap"
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import Frame from '../../../Components/Frame'
import { FormUppyUploader } from '../../../interfaces'
import { useFormContext } from 'react-hook-form'
import { FaCamera } from 'react-icons/fa'
import { base_url, POSTRequest } from '../../../../apiHandler'

const UppyUploader = (props: FormUppyUploader) => {
  const ref = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const methods = useFormContext();

  const watchFile = methods.watch(props.name)
  const uploadedFiles = useMemo(()=>watchFile,[watchFile])

  const uppy = useUppy(() => {
    return new Uppy({
      store: new DefaultStore(),
      meta : {a : "ADD_FILE"},
      onBeforeFileAdded: (currentFile:any, files) => {
        const _name = Date.now() + currentFile.name
        const modifiedFile:any = {
          ...currentFile,
          meta : {
            ...currentFile.meta,
            name : _name
          },
          name:  _name,
        }
        console.log("before File Upload", currentFile, modifiedFile)
        return modifiedFile
      },
    }).use(XHRUpload, { bundle:true, endpoint: base_url + `uploads/uploadHandler.php` })
  })

  uppy.on('file-removed', (result, reason) => {
    console.log("REMOVE_FILE",result, reason)
    POSTRequest("uploads/uploadHandler.php","REMOVE_FILE",{name : result.name})
    .then((data)=> {
      methods.setValue(props.name, (methods.getValues(props.name) as string[]).filter(_file => _file !== result.name))
    })
  })

  uppy.on('complete', (result) => {
    console.log('Complete',result.successful[0].response?.body.status)
    if (result.successful[0].response?.body.status) { 
      const _values = new Set([...methods.getValues(props.name) ?? [] , ...result.successful[0].response?.body.data as Array<string>])
      methods.setValue(props.name, Array.from(_values))
    }
  })

  return (
    <Frame {...props}>       
      <DashboardModal 
        target="body"
        doneButtonHandler={()=> {console.log("DoneButtonHandling"); setOpen(false)}}
        closeModalOnClickOutside
        metaFields={[
          { id: 'name', name: 'Name', placeholder: 'file name' }
        ]}
        uppy={uppy}
        open={open}
        onRequestClose={()=>setOpen(false)}
        hideProgressAfterFinish
        showProgressDetails
        showRemoveButtonAfterComplete
      />
      <Button ref={ref} variant="outline-primary" onClick={()=>setOpen(true)}><FaCamera /> {ref.current !== null ?  ref.current?.offsetWidth < 150 ? '' : 'Upload Photo' :''} <Badge>{uploadedFiles?.length ?? 0}</Badge></Button>
      <Row>
        
      </Row>
    </Frame>
  )
}

export default UppyUploader