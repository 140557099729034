import React from 'react'
import {QrReader} from 'react-qr-reader' 

import {Modal} from "react-bootstrap"

export interface QRContainerProps {
  show : boolean,
  onHide : ()=>void,
  delay ?: number,
  onChange ?: (value: any) => void
  handleError ?: (value: any) => void,
  handleScan ?: (value: any) => void 
  style ?: any
}

export const QRContainer = (props : QRContainerProps) => {
  const _styles = {
    height : 195,
    ...props.style
  }
  return (<Modal show={props.show} onHide={()=>props.onHide()} size="sm" centered>
    <Modal.Header>
      <Modal.Title>QR Scanner</Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-center" style={{height:230}}>
      {props.show && <QrReader
        constraints={{facingMode:'environment'}}
        scanDelay={props.delay ?? 100}
        videoContainerStyle={_styles}
        onResult={(data:any, error)=>{
          if (!!data && props.handleScan !== undefined ) { props.handleScan(data); props.onHide() }
          if (!!error && props.handleError !== undefined ) { props.handleError(error); props.onHide()}
        }}
      />}
    </Modal.Body>
  </Modal>)
}
