import React, {useState} from 'react'
import {Button} from 'react-bootstrap'
import { FaQrcode } from 'react-icons/fa'
import { QRContainer } from './base'

export interface QRWithButtonProps {
  delay ?: number,
  onChange ?: (value: any) => void
  handleError ?: (value: any) => void,
  handleScan ?: (value: any) => void 
  style ?: any
}

export const QRWithButton = (props : QRWithButtonProps) => {
  const [show, setShow] = useState<boolean>(false)
  return (<>
    <Button onClick={() => setShow(true)} variant="outline-light" style={{fontSize:'1.4em'}}><FaQrcode className="text-dark" /></Button> 
    <QRContainer show={show} onHide={() => setShow(false)} {...props} />
  </>)
}

