import React from 'react'
import { AugTable } from '../../components/Table'
import ContentFrame from '../../ContentTemplate/ContentFrame'

const Supplier = () => {
  return (
    <ContentFrame title="Supplier" >
      <AugTable 
        allowResizing
        columns={[
          {field:'c_id',headerText:'Supplier ID',isIdentity:true},
          {field:'c_name',headerText:'Name'},
          {field:'c_address',headerText:'Address'},
          {field:'c_email',headerText:'Email'},
          {field:'c_dateCreated',headerText:'Date Added',isIdentity:true},
        ]}
        allowEditing
        dataManagerUrl={{
          url: 'data/supplier.php',
          insertUrl : 'data/supplier-crud.php',
          updateUrl : 'data/supplier-crud.php',
          removeUrl : 'data/supplier-crud.php'
        }}
      />
    </ContentFrame>
  )
}

export default Supplier