import { handleIncomingQR } from "./incoming";
import { handleWildCardQR } from "./items";
import { handleItemQR } from "./items";

export const COMPANY_NAME = "Cleanroom Industries Sdn. Bhd."
export const COMPANY_PREFIX = "CLIN"
export const ITEM_PREFIX = "IT";
export const INCOMING_PREFIX = "IN";

export const qr_finder = (data) => new Promise((resolve, reject) => {
  if (typeof data !== "string") {resolve(false)}

  const data_split = data.split("/")

  if (data_split[0] !== COMPANY_PREFIX) {
    resolve([
      {title: "Company", value: "Others"},       
      {title : "_separator_"},
      {title:"Value",value: data}
    ])}

  switch(data_split[1]) {
    case ITEM_PREFIX:
      handleItemQR(data).then(ret => {
        resolve([
          {title: "Company", value: COMPANY_NAME},          
          {title : "_separator_"},
          {title : "Component ID", value : data}, 
          {title: "Component Name",value : ret.data.label}
        ])})
      break;
    case INCOMING_PREFIX:
      handleIncomingQR(data).then(ret => {
        const lot_data = [
          {title: "Company", value: COMPANY_NAME},
          {title : "_separator_"},
          {title: "Lot ID", value: ret.data.l_id},
          {title: "Lot Item ID", value: ret.data.lt_id ?? null},
          {title: "PO Number", value: ret.data.po_number},
          {title: "Date Created", value: ret.data.dateCreated},
          {title: "Requested By", value: ret.data.requested_by},
          {title: "Lot Status", value: ret.data.status},
        ]

        if(ret.is_item) {
          lot_data.push(...[
            {title : "_separator_"},
            {title : "Item", value: ret.data.item_name},
            {title : "Item Quantity", value: ret.data.quantity},
            {title : "Item Cost", value: ret.data.cost},
          ])
        }        
        resolve(lot_data)
      })
      break;
    default:
      handleWildCardQR(data).then((res) => {
        console.log("Response",res)
        const lot_data = res.map(_res => {
          switch (_res.type) {
            case 'warehouse':
              return [
                {title : "_title_",value:"Warehouse Data"},
                {title:'Warehouse Name', value : _res.data.w_name},
                {title:'Warehouse Location', value : _res.data.w_location},
                {title:'Warehouse Prefix', value : _res.data.w_type_idx},
              ]
            case 'storage_type':
              return [
                {title : "_title_",value:"Storage Type Data"},
                {title:'Type Name', value : _res.data.st_name},
                {title:'Type Prefix', value : _res.data.st_type_idx},
              ]
            case 'storage_bin':
              return [
                {title : "_title_",value:"Storage Bin Data"},
                {title:'Bin Name', value : _res.data.sb_name},
                {title:'Bin Prefix', value : _res.data.sb_type_idx},
              ]
            case 'item':
              return [
                {title : "_title_",value:"Item Data"},
                {title:'Item Name', value : _res.data.i_name},
                {title:'Item Description', value : _res.data.i_desc},
              ]
            default: 
              return [
                {title :'Other', value : _res.data},
                {title : "_separator_"},
              ]
          }

        })

        resolve(lot_data.flat())


      })
      break;
  }
})