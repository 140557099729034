import React, {useState, useEffect} from 'react'
import {Table} from "react-bootstrap"
import { qr_finder } from '../qrHandling/core'

interface BarcodeTableDataProps {
  data : string
}

const BarcodeTableData = (props : BarcodeTableDataProps) => {
  const [tableRows, setTableRows] = useState<JSX.Element[]>([])
  useEffect(()=>{
    qr_finder(props.data)
    .then(_res => {
      setTableRows(_res === false ? [<></>] : _res.map(__res => (
        __res.title === '_separator_' ? 
          <tr><td colSpan={2} className="border-b-stone-800">------------------------------------------</td></tr> :           
          __res.title === '_title_' ? 
          <tr className="table-dark"><th colSpan={2} className="border-b-stone-800">{__res.value}</th></tr> :           
          <tr><th>{__res.title}</th><td>{__res.value}</td></tr>
      )))
    })
  },[props.data])
  return (
    <Table bordered><tr>{tableRows}</tr></Table>
  )
}

export default BarcodeTableData