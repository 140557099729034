import React from 'react'
import { QRCodeImg } from '../../../../components/QRCode'
import { AugTable } from '../../../../components/Table'
import ContentFrame from '../../../../ContentTemplate/ContentFrame'

import ItemsButtonGroup from './components/ItemsButtonGroup'

export interface LotTableFields {
  no ?: string,
  l_id ?: string,
  company ?: string,
  po_number ?: string,
  status ?: string,
  requested_by ?: string,
  dateCreated ?: string,
}

export interface LotTableItemsFields {
  no : string,
  lt_id : string,
  lt_i_id : string,
  component : string,
  quantity : string,
  cost : string,
  unit : string,
  status : string,
  dateUpdated : string,
}

export const emptyLotItems: LotTableItemsFields = {
  no : "",
  lt_id : "",
  lt_i_id : "",
  component : "",
  quantity : "",
  cost : "",
  unit : "",
  status : "",
  dateUpdated : "",
}

const ViewGoodsReceivedReceipts = () => {
  const customizeLotTable = (cellInfo:any) => {
    if (cellInfo.column.field === "images" && cellInfo.data.images !== null && cellInfo.cell)  {
      console.log(cellInfo)
      const _images  = cellInfo.data.images.split(",")
      console.log(_images.map((_image:string) => `<img src="/api/uploads/${_image}" height="100" />`).reduce((a:string,b:string) => a + b, ""))
      cellInfo.cell.innerHTML = _images.map((_image:string) => `<img src="/api/uploads/${_image}" height="100" />`).reduce((a:string,b:string) => a + b, "")
    }
  }

  const itemsListLayout = (data : LotTableItemsFields) => {
    return <tr>
      <td className="text-center">{data.no}</td>
      <td style={{width:150}}>
        <div>
          <p className="text-center mb-0">{data.lt_id}</p>
          <QRCodeImg value={data.lt_id} size="s" />
        </div>
      </td>
      <td style={{width:150}}>
        <div>
          <p className="text-center mb-0">{data.lt_i_id}</p>
          <QRCodeImg value={data.lt_i_id} size="s" />
        </div>
      </td> 
      <td className="text-center">{data.component}</td>
      <td className="text-center">{data.quantity}</td>
      <td className="text-center">{data.unit}</td>
      <td className="text-center">{data.cost}</td>
      <td className="text-center">{data.status}</td>
      <td className="text-center">{data.dateUpdated}</td>
      <td className="text-center">        
        <ItemsButtonGroup data={data} />
      </td>
    </tr> 
  }

  return (<>
    <ContentFrame title="View Received Goods Receipts">
      <AugTable 
        allowResizing 
        allowGroup
        dataManagerUrl={{url : "incoming.php"}}
        query={[{param:"a",value:"LOT_TABLE"}]}
        customizeCell={customizeLotTable}
        columns={[
          {field: 'no', headerText : "No"},
          {field: 'l_id', headerText : "Incoming ID" ,width: 300},
          {field: 'company', headerText : "Supplier"},
          {field: 'po_number', headerText : "PO Number"},
          {field: 'status', headerText : "Status"},
          {field: 'no_lot', headerText : "No of Lots"},
          {field: 'requested_by', headerText : "Requester"},
          {field: 'dateCreated', headerText : "Date Created"},
          {field: 'tot_cost', headerText : "Total Cost (RM)"},
          {field: 'rem', headerText : "Remaining in Lot"},
          {field: 'images', headerText : "Images(s)"},
        ]}
        detailsTemplate={(det : LotTableFields) => 
          <AugTable     
            allowResizing 
            rowTemplate={itemsListLayout}
            dataManagerUrl={{url : "incoming.php"}}
            query={[
              {param : "a",value : 'LOT_ITEM_TABLE' },
              {param : "l_id",value : det.l_id ?? '' },
            ]}
            columns={[
              {textAlign:"Center", field:'no', headerText : "No"},
              {width: 200, textAlign:"Center", field:'lt_id', headerText : "Lot Item ID"},
              {width: 200, textAlign:"Center", field:'lt_i_id', headerText : "Component ID"},
              {textAlign:"Center", field:'component', headerText : "Component Name"},
              {textAlign:"Center", field:'quantity', headerText : "Quantity"},
              {textAlign:"Center", field:'unit', headerText : "Unit"}, 
              {textAlign:"Center", field:'cost', headerText : "Cost"},
              {textAlign:"Center", field:'status', headerText : "Status"},
              {textAlign:"Center", field:'dateUpdated', headerText : "Date Updated"},
              {textAlign:"Center", field:'action', headerText : "Actions"},
            ]}
          />
        }
      /> 
    </ContentFrame> 
  </>)
}

export default ViewGoodsReceivedReceipts