import React from 'react'
import { QRCodeCont } from './components/QRContainer'
import "./qrCode.scss"

export interface QRCodeImgProps {
  value : string,
  size ?: "s" | "l",
  noModal ?: boolean,
  inline ?: boolean
}

export const QRCodeImg = (props : QRCodeImgProps)  => {
  return (
    <div style={{ background: 'white', padding: '16px', height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
        {
        props.value === undefined ? 
        <></> : <QRCodeCont {...props}/>
        }
    </div>
  )
}
