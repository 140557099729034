import axios from "axios";

export const base_url = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "http://st-api.cleanroom-industries.com/api/" : "http://st-api.my-augment.com/api/";

export const POSTRequest = <T=any,>(url:string, a:string, data?: any) => 
  new Promise<T|null>((resolve, reject) => {
    axios.post(
      base_url + url,
      {
        a:  a,
        ...data
      }
    ).then(response => {
      console.log("[POSTRequest] - Response", response)
      if (response.status === 200 && response.data.status) {
        resolve(response.data.data);
      } else {
        resolve(null);
      }
    })
  })