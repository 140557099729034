import React from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';

export interface TooltipWrapperProps {
  id ?: string,
  key ?: string,
  placement ?: 'top' | 'bottom' | 'left' | 'right'
  children: JSX.Element,
  tooltip : React.ReactNode
}

const ChildWrapper = React.forwardRef((props: TooltipWrapperProps, ref) => {
  return <>{props.children}</>
})
export const TooltipWrapper = (props: TooltipWrapperProps) => {
  return (
    <OverlayTrigger
      key={`tt-k-${props.key ?? new Date().getTime()}`}
      placement={props.placement ?? 'right'}
      overlay={<Tooltip id={`tooltip-${props.id ?? new Date().getTime()}`}>{props.tooltip}</Tooltip>}
    >
      <ChildWrapper {...props} />
    </OverlayTrigger>
  )
}