import React, {useRef} from 'react';
import './App.scss';
import { ToolbarComponent, ItemsDirective, ItemDirective, MenuComponent } from '@syncfusion/ej2-react-navigations';
import { Outlet, useNavigate } from 'react-router-dom';
import { ToastContainer } from "react-toastify";

import Sidebar from "./PageTemplate/Sidebar"
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const navigate = useNavigate();
  const sidebarRef = useRef<null|HTMLElement|any>(null);

  const toolbarClicked = (args:any) => {
    if(args.item.tooltipText === "Menu") {
        sidebarRef.current.toggle();
    }
  }

  let folderEle: string = '<div class= "e-folder"></span><div class= "e-folder-name">Navigation Pane</div></div>';

  const _quick_actions = [
    { 
      text : "Add New Material",
      id : "/materials/add",
      iconCss : "fa-solid fa-plus text-success fw-bold"
    },
    { 
      text : "Add Incoming Supply",
      id : "/incoming/receiving/add",
      iconCss : "fa-solid fa-plus text-success fw-bold"
    },
    {
      text : "Add Material To Project",
      id : "/projects/material/add",
      iconCss : "fa-solid fa-wrench text-danger fw-bold"
    }
  ]

  const quickAction = (args: any) => {navigate(args.item.id)}

  return (
    <div id="wrapper" className="control-section">
    <div id="sidebarmenu">
        {/* header-section  declaration */}
        <div>
            <ToolbarComponent id="menuToolbar" clicked={toolbarClicked}>
                <ItemsDirective>
                    <ItemDirective prefixIcon="fa-solid fa-bars" tooltipText="Menu"></ItemDirective>
                    <ItemDirective template={folderEle} cssClass="pe-5"></ItemDirective>
                    <ItemDirective template={()=><MenuComponent select={quickAction} items={_quick_actions}></MenuComponent>}/>
                </ItemsDirective>
            </ToolbarComponent>
        </div>
        {/* main content declaration */}
        <div className="main-menu-content h-100" id="maintext">
            <div className="menu-content  ps-4 pt-3 h-100">
              <Outlet/>
            </div>
        </div>
        {/* end of main content declaration
        sidebar element declaration */}
        <Sidebar ref={sidebarRef} />
    </div>
    
    <ToastContainer />
</div>);
}


export default App;
