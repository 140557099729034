import React, {useState} from 'react'
import Frame from '../../Components/Frame'
import {Row,Col} from "react-bootstrap"
import { FormSelectWithQRProps } from '../../interfaces'
import { FaQrcode } from 'react-icons/fa'
import { SelectCreatable, SelectNoAdd } from './Select'
import { useFormContext } from 'react-hook-form'
import { QRContainer } from '../QR'


const SelectWithQR = (props : FormSelectWithQRProps) => {
  const [scanning, setScanning] = useState<boolean>(false)
  const methods = useFormContext();
  
  const handleError = (error: any) => {}
  const handleScan = (data: any) => {
    console.log("QRData",data)  

    // Validation , if valid then select value
    if (props.qrValidation) {
      props.qrValidation(data.text, methods.getValues()).then((returnVal) => {
        if (returnVal.status) {
          console.log("[qrValidation] - Return Value : ",returnVal, methods.getValues(props.name))
          if (props.valueAsState) {props.valueAsState(returnVal.data)}
          methods.setValue(props.name, returnVal.data, {shouldValidate : true}) 
        }
      })
    }
  }

  return (
    <Frame {...props}>
      <Row>
        <QRContainer show={scanning} onHide={()=>setScanning(false)} delay={100} handleScan={handleScan} handleError={handleError} style={{width: 240}} />
        <Col xs={12}>
          {
            props.onCreate ? 
              <SelectCreatable isClearable noFrame {...props} caption="" name={props.name} onCreate={props.onCreate ?? ((dat:string)=>null)} options={props.options} /> :
              <SelectNoAdd isClearable styles={{container: (base) => ({...base, width : "calc(100% - 42px)"})}} showTitle={false} buttonLeft={[{caption:<FaQrcode />, onClick: ()=> setScanning(!scanning), variant: 'outline-dark'}]} noFrame {...props} caption="" name={props.name} options={props.options} />
          }
        </Col>
      </Row>
    </Frame>
  )
}

export default SelectWithQR