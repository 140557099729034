import {useState, useEffect} from 'react'
import {useFormContext, Controller} from "react-hook-form"
import { FormSelectInterface, FormSelectCreatableInterface, FormSelectOptionsInterface } from "../../interfaces";
import Select from "react-select"; 
import Creatable from "react-select/creatable"

import Frame from "../../Components/Frame"
import FrameContextless from "../../Components/FrameContextless"
import { POSTRequest } from '../../../apiHandler';

export const SelectCreatable  = (props: FormSelectCreatableInterface) => {
  // const [selValue, setSelValue] = useState<any>(props.valueSetting ? props.valueSetting : {});
  const [options, setOptions] = useState<FormSelectOptionsInterface[]>(props.options ?? [])
  const methods = useFormContext();


  useEffect(()=>setOptions(props.options ?? []),[props.options])
  // useEffect(()=>{if (methods.getValues(props.name)?.value !== selValue?.value) {methods.setValue(props.name,selValue.value)}},[options,methods.getValues(props.name)])

  const process_change = (val : any) => {
    console.log("[process_change] - val",val, props.setValue)
    if (props.setValue) { 
      if (typeof props.setValue.value === 'function') {
        methods.setValue(props.setValue.inputName, props.setValue.value(val, methods.getValues(props.setValue.inputName), methods.getValues()))
      } else {
        methods.setValue(props.setValue.inputName, props.setValue.value)
      }
    }
  }

  const createOptionHandler = (value : string, onChange: (...event: any[]) => void) => {
    const _options = [...options];
    let _newOption = null;
    if (typeof props.onCreate === 'function') { 
      props.onCreate(value)
      .then((ret:FormSelectOptionsInterface) => {
        // setOptions([...options,ret])
        _newOption = ret;
        // setSelValue(ret)
        // onChange(ret)
      })
    } 
    // if type exists
    else {
      if (props.onCreate.type === 'local') {
        // setOptions([...options,{label: value, value: value} ]) 
        // _options.push({label: value, value: value}) 
        _newOption = {label: value, value: value}
        // // setSelValue({label: value, value: value})
        // onChange({label: value, value: value})   
      }
      else {
        POSTRequest(props.onCreate.url,props.onCreate.action,value)
        .then((ret:FormSelectOptionsInterface) => {
          _newOption = ret
          // setOptions([...options,ret])
          // // setSelValue(ret)
          // onChange(ret)
        })
      }
    }

    if (_newOption !== null) {
      _options.push(_newOption);
      setOptions(_options)
      if (props.extOptions) {props.extOptions(_options)}
      onChange(_newOption)
    }

  }
  return ( 
    props.noFrame ? 
      <Controller 
        control={methods.control}
        name={props.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <Creatable           
            menuPortalTarget={document.querySelector('body')}
            isClearable
            onChange={(c)=>{onChange(c);process_change(c); if (props.valueAsState) {props.valueAsState(c)}}}             
            onCreateOption={(val:string)=>createOptionHandler(val, onChange)}
            options={options}
            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
            value={value} 
          />
        )}
      /> : 
    <Frame {...props}>
      <Controller 
        control={methods.control}
        name={props.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <Creatable
            isClearable
            menuPortalTarget={document.querySelector('body')}
            onChange={(c)=>{onChange(c);process_change(c); if (props.valueAsState) {props.valueAsState(c)}}}             
            onCreateOption={(val:string)=>createOptionHandler(val, onChange)}
            options={options}
            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
            value={value} 
          />
        )}
      />
    </Frame>
  )
}

export const SelectNoAdd  = (props: FormSelectInterface) => {
  const { control, setValue } = useFormContext();
  const [selValue, setSelValue] = useState(null);
  useEffect(()=>{
    if(selValue === null) {return}
    if (props.setValue) { setValue(props.setValue.inputName, props.setValue.value) }
    if (props.setValues) { props.setValues.inputName.forEach((inputName,index) => setValue(inputName,props.setValues?.value[index as keyof typeof props.setValues.value]))}
    // eslint-disable-next-line
  },[props?.setValue?.value, props?.setValues?.value])
  

  return (    
    <Frame {...props}>
      <Controller 
        control={control}
        name={props.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <Select 
            isClearable={props.isClearable}
            styles={props.styles}
            menuPortalTarget={document.querySelector('body')}
            options={props.options}
            value={value} 
            onChange={(c)=>{onChange(c);setSelValue(c); if (props.valueAsState) {props.valueAsState(c)}}} 
            isMulti={props.isMulti}
            isDisabled={props.readOnly}
          />
        )}
      />
    </Frame>
  )
}

export const SelectNoAddContextless  = (props: FormSelectInterface) => {
  const [selValue, setSelValue] = useState<any>(null);
  useEffect(()=>{
    if(selValue === null) {return}
    // eslint-disable-next-line
  },[props?.setValue?.value])

  return (    
    <FrameContextless {...props}>
      <Select 
        isClearable={props.isClearable}
        styles={props.styles}
        menuPortalTarget={document.querySelector('body')}
        options={props.options}
        value={selValue} 
        onChange={(c)=>{setSelValue(c); if (props.valueAsState) {props.valueAsState(c)}}} 
        isMulti={props.isMulti}
      />
    </FrameContextless>
  )
}

export const SelectMulti  = (props: FormSelectInterface) => {
  const { control } = useFormContext();

  return (    
    <Frame {...props}>
      <Controller 
        control={control}
        name={props.name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <Select 
            isClearable={props.isClearable}
            styles={props.styles}
            menuPortalTarget={document.querySelector('body')}
            isMulti
            options={props.options}
            value={value} 
            onChange={onChange} 
          />
        )}
      />
    </Frame>
  )
}
