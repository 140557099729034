import React from "react";
import ContentFrame from "../../../ContentTemplate/ContentFrame";
import { POSTRequest } from "../../../components/apiHandler";
import {
  AddMaterialForm,
  IAddMaterialReturnForm,
} from "../../../forms/Materials/AddMaterial";
import { useNavigate } from "react-router-dom";

// type options_type = {
//   supplier:FormSelectOptionsInterface[],
//   material : FormSelectOptionsInterface[]
// }

const AddMaterial = () => {
  const navigate = useNavigate();

  const addMaterial = (data: IAddMaterialReturnForm) => new Promise((resolve, reject) => {
      POSTRequest("materials.php", "ADD_MATERIAL", data).then((response) => {
        alert("Material Added!");
        navigate("/materials/view");
      });
  })

  return (
    <ContentFrame title="Add New Material">
      <AddMaterialForm onSubmit={addMaterial} />
    </ContentFrame>
  );
};

export default AddMaterial;
