import React, {useEffect} from 'react'

import {Modal} from "react-bootstrap"
import { LotTableItemsFields } from '..'

interface LotModalProps {
  show : boolean,
  onHide : ()=>void,
  data : LotTableItemsFields
}

const LotModal = (props: LotModalProps) => {

  useEffect(()=>{
    if (props.show === false) {return}

  },[props.show])
  return (
    <Modal show={props.show} onHide={()=>props.onHide()} size="xl">
      <Modal.Header>
        <Modal.Title>Lot Info for {props.data.lt_id}</Modal.Title>
      </Modal.Header>
      <Modal.Body></Modal.Body>
    </Modal>
  )
}

export default LotModal