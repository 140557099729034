import React from 'react'
import { Col } from 'react-bootstrap'
import { QRCodeImg } from '../../../../components/QRCode'
import { AugTable } from '../../../../components/Table'
import ContentFrame from '../../../../ContentTemplate/ContentFrame'

const ViewInventory = () => {
  const binTemplate = (data:any) => {
      
    return <tr>
    <td className="text-center">{data.sb_name}</td>
    <td className="text-center">{data.st_name}</td>
    <td className="text-center">{data.w_name}</td>
    <td className="text-center">{data.s_quantity}</td>
    <td className="text-center">{data.transactions}</td>
    <td style={{width:150}}>
      <div>
        <p className="text-center mb-0">{data.prefix}</p>
        <QRCodeImg value={data.prefix} size="s" />
      </div>
    </td>
  </tr>
  }

  return (
    <ContentFrame title="View Inventory">
      <Col xs={12}><p>All Items and Inventory Managed by the Stock Tracker can be viewed here</p></Col>
      <Col xs={12}>
        <AugTable
          dataManagerUrl={{url:'tables/inventory.php'}}
          query={[{param:'a',value:'INV_TABLE'}]}
          columns={[
            {headerText: 'ID',field:'i_id'},
            {headerText: 'Name',field:'i_name'},
            {headerText: 'Description',field:'i_description'},
            {headerText: 'Bin Count',field:'bin_count'},
            {headerText: 'Quantity On Hand',field:'total_quantity'},
            {headerText:'Quantity In',field: 'in_quantity'},
            {headerText:'Quantity Out',field: 'out_quantity'},
          ]}
          detailsTemplate={(a:any) => <AugTable 
            rowTemplate={binTemplate}
            dataManagerUrl={{url:'tables/inventory.php'}}
            query={[{param:'i_id',value:a.i_id},{param:'a',value:'BIN_TABLE'}]}
            columns={[
              {headerText: 'Bin', field : 'sb_name'},
              {headerText: 'Storage Type', field : 'st_name'},
              {headerText: 'Warehouse', field : 'w_name'},
              {headerText: 'Quantity', field : 's_quantity'},
              {headerText: 'Transaction Count', field : 'transactions'},
              {headerText: 'Designation', field : 'prefix'},
            ]}
          />}
        />
      </Col>
    </ContentFrame>
  )
}

export default ViewInventory