import React from 'react'
import { QRCodeImg } from '../../../components/QRCode'
import { AugTable } from '../../../components/Table'
import ContentFrame from '../../../ContentTemplate/ContentFrame'

const ViewMaterials = () => {
  const itemsListLayout = (data : any) => {
    return <tr>
      <td className="text-center">{data.no}</td>
      <td className="text-center">{data.image !== null  ? <img alt={data.i_name} width="100" src={`/api/uploads/${data.image?.split(",")?.[0]}`} /> : "No Photos"}</td>
      <td style={{width:150}}>
        <div>
          <p className="text-center mb-0">{data.i_id}</p>
          <QRCodeImg value={data.i_id} size="s" />
        </div>
      </td>
      <td className="text-center">{data.i_name}</td>
      <td className="text-center">{data.units}</td>
      <td className="text-center">{data.quantities}</td>
      <td className="text-center">{data.last_incoming}</td>
      <td className="text-center">{data.last_supplier}</td>
    </tr> 
  }
  return (<>
    <ContentFrame title="View Existing Material Catalogue">
      <AugTable 
        rowTemplate={itemsListLayout}
        allowResizing 
        allowGroup
        dataManagerUrl={{url : "materials.php"}}
        query={[{param:"a",value:"MATERIAL_TABLE"}]}
        columns={[
          {field: 'no', headerText : "No"},
          {field: 'image', headerText : "Images"},
          {field: 'i_id', headerText : "Item ID" ,width: 300},
          {field: 'i_name', headerText : "Item Name"},
          {field: 'units', headerText : "Type of Units(s)"},
          {field: 'quantities', headerText : "Quantities"},
          {field: 'last_incoming', headerText : "Last Incoming"},
          {field: 'last_supplier', headerText : "Last Supplier"},
        ]}
      /> 
    </ContentFrame> 
  </>)
}

export default ViewMaterials 